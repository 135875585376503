import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class BasicService {
  public showIssues = true;
  public mailAddress = "Indira4DSM@gmail.com";
  public facebook = "https://www.facebook.com/Indira4DSM";
  public twitter = "https://twitter.com/Indira4DSM";
  public instagram = "https://www.instagram.com/Indira4DSM";
  public volunteerLink = "https://t.co/tOLWeBSt6S?amp=1";
  public donateLink = "https://secure.actblue.com/donate/indira";
  public speakCouncilLink = "https://www.dsm.city/interactive_forms/request_to_speak_before_the_city_council.php";
  public watchCouncilLink = "https://www.dsm.city/government/council_meetings_and_agendas/meeting_schedule.php";
}
