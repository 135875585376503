import {HttpHeaders} from "@angular/common/http";

export abstract class CookieHelper {

  public static get authHeaders() {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return {
      headers,
    };
  }
}
