import {Component} from "@angular/core";
import {NavHelperService} from "../../services/nav-helper.service";
import {BasicService} from "../../services/basic.service";
import {IssueService} from "../../services/issue.service";

@Component({
  selector: "app-issue-row",
  templateUrl: "./issue-row.component.html",
  styleUrls: ["./issue-row.component.scss"]
})
export class IssueRowComponent {
  constructor(
    public navHelper: NavHelperService,
    public basicService: BasicService,
    public issueService: IssueService,
  ) {
  }

}
