import {Component} from "@angular/core";
import {NavHelperService} from "../../services/nav-helper.service";
import {BasicService} from "../../services/basic.service";
import {IssueService} from "../../services/issue.service";

@Component({
  selector: "app-issues",
  templateUrl: "./issues.component.html",
  styleUrls: ["./issues.component.scss"]
})
export class IssuesComponent {
  constructor(
    public navHelper: NavHelperService,
    public basicService: BasicService,
    public issueService: IssueService,
  ) {
  }
}
