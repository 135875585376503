import {Component} from "@angular/core";
import {BooleanHelper} from "../../utilities/boolean.util";
import {BasicService} from "../../services/basic.service";
import {SignupService} from "../../services/signup.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"]
})
export class SignupComponent {
  public firstname: string;
  public lastname: string;
  public email: string;

  public showErrors = false;
  public inProgress = false;
  public submitted = false;

  public get emailValid(): boolean {
    return BooleanHelper.hasValue(this.email);
  }

  public get firstnameValid(): boolean {
    return BooleanHelper.hasValue(this.firstname);
  }

  public get lastnameValid(): boolean {
    return BooleanHelper.hasValue(this.lastname);
  }

  public get valid(): boolean {
    return this.firstnameValid && this.lastnameValid && this.emailValid;
  }

  constructor(
    public basicService: BasicService,
    private signupService: SignupService,
  ) {
  }

  public signUp() {
    this.showErrors = true;
    if (this.valid) {
      this.signupService.signUp();
      this.inProgress = true;
      this.submitted = true;
    }
  }

}
