import {Component} from "@angular/core";
import {BasicService} from "../../services/basic.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent {
  public get mailto(): string {
    return `mailto:${this.basicService.mailAddress}`;
  }

  public get year(): number {
    return new Date().getFullYear();
  }

  constructor(
    public basicService: BasicService,
  ) {
  }
}
