import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-body-alt",
  templateUrl: "./body-alt.component.html",
  styleUrls: ["./body-alt.component.scss"]
})
export class BodyAltComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
