import {Injectable} from "@angular/core";
import {ROUTES_ENUM} from "../constants/routing.constants";

export interface Issue {
  name: string;
  icon: string;
  title: string;
  subtitle: string;
  details: string;
}

@Injectable({
  providedIn: "root"
})
export class IssueService {
  public issues: Issue[] = [
    {
      name: ROUTES_ENUM.Communities,
      icon: "fa-money",
      title: "Strong Communities Make Police Obsolete",
      subtitle: "Defund the Police for Safety and Justice",
      details: "DETAILS HERE",
    },
    {
      name: ROUTES_ENUM.Housing,
      icon: "fa-building",
      title: "Housing & Neighborhoods: From Surviving to Thriving",
      subtitle: "Accessible Food and Housing Builds Stable Communities",
      details: "DETAILS HERE",
    },
    {
      name: ROUTES_ENUM.Infrastructure,
      icon: "fa-plug",
      title: "Public Transportation and Municipal Utilities",
      subtitle: "Health Infrastructure for a Sustainable Future",
      details: "DETAILS HERE",
    },
    {
      name: ROUTES_ENUM.Government,
      icon: "fa-users",
      title: "A People's Government Against Corporate Greed",
      subtitle: "Redirect Power, Redistribute Funds",
      details: "DETAILS HERE",
    },
  ];
}
