import {Component} from "@angular/core";
import {NavHelperService} from "src/app/services/nav-helper.service";
import {BasicService} from "../../services/basic.service";
import {LogService} from "../../services/log.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent {
  constructor(
    public basicService: BasicService,
    private navHelper: NavHelperService,
    public logService: LogService,
  ) {
  }

  public goToDashboard(): void {
    this.navHelper.goToDashboard();
  }

  public goToAbout(): void {
    this.navHelper.goToAbout();
  }

  public goToIssues(): void {
    this.navHelper.goToIssues();
  }
}
