import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {ROUTES_ENUM} from "./constants/routing.constants";
import {IssuesComponent} from "./components/issues/issues.component";
import {AboutComponent} from "./components/about/about.component";
import {IssueDetailsComponent} from "./components/issue-details/issue-details.component";

const routes: Routes = [
  {path: ROUTES_ENUM.Dashboard, component: DashboardComponent},
  {path: ROUTES_ENUM.Issues, component: IssuesComponent},
  {path: ROUTES_ENUM.About, component: AboutComponent},
  // {path: "issues/:issueName", component: IssueDetailsComponent},
  // default
  {path: "**", redirectTo: ROUTES_ENUM.Dashboard},
];

@NgModule({
  exports: [RouterModule],
  declarations: [],
  imports: [RouterModule.forRoot(routes, {useHash: true})],
})
export class AppRoutingModule {
}
