import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {ROUTES_ENUM} from "../constants/routing.constants";

@Injectable({
  providedIn: "root"
})
export class NavHelperService {

  constructor(
    private router: Router,
  ) {
  }

  public goToDashboard(): void {
    this.goToRoute(ROUTES_ENUM.Dashboard);
  }

  public goToAbout(): void {
    this.goToRoute(ROUTES_ENUM.About);
  }

  public goToIssues(): void {
    this.goToRoute(ROUTES_ENUM.Issues);
  }

  public goToIssue(issue): void {
    this.goToRoute("issues/" + issue);
  }

  // GENERAL

  private goToRoute(route: string): void {
    this.goToRoutes([route]);
  }

  private goToRoutes(routes: string[]): void {
    this.router.navigate(routes);
    window.scrollTo(0, 0);
  }
}
