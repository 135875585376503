import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";

import {AppComponent} from "./app.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {AppRoutingModule} from "./app-routing.module";
import {NavbarComponent} from "./components/navbar/navbar.component";
import {FooterComponent} from "./components/footer/footer.component";
import {HttpClientModule} from "@angular/common/http";
import {HeaderComponent} from "./components/header/header.component";
import {BodyComponent} from "./components/body/body.component";
import {LoadingComponent} from "./components/loading/loading.component";
import {CardComponent} from "./components/card/card.component";
import {BodyAltComponent} from "./components/body-alt/body-alt.component";
import {AboutComponent} from "./components/about/about.component";
import {IssuesComponent} from "./components/issues/issues.component";
import {IntroComponent} from "./components/intro/intro.component";
import {AboutRowComponent} from "./components/about-row/about-row.component";
import {IssueRowComponent} from "./components/issue-row/issue-row.component";
import {InvolveRowComponent} from "./components/involve-row/involve-row.component";
import {IssueBaseComponent} from "./components/issue-base/issue-base.component";
import {SignupComponent} from "./components/signup/signup.component";
import {IssueDetailsComponent} from "./components/issue-details/issue-details.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    BodyComponent,
    LoadingComponent,
    CardComponent,
    BodyAltComponent,
    AboutComponent,
    IssuesComponent,
    IntroComponent,
    AboutRowComponent,
    IssueRowComponent,
    InvolveRowComponent,
    IssueBaseComponent,
    SignupComponent,
    IssueDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
