import {Component} from "@angular/core";
import {NavHelperService} from "../../services/nav-helper.service";

@Component({
  selector: "app-about-row",
  templateUrl: "./about-row.component.html",
  styleUrls: ["./about-row.component.scss"]
})
export class AboutRowComponent {
  constructor(
    private navHelper: NavHelperService,
  ) {
  }

  public goToAbout() {
    this.navHelper.goToAbout();
  }

}
