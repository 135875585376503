import {Component} from "@angular/core";
import {BasicService} from "../../services/basic.service";

@Component({
  selector: "app-involve-row",
  templateUrl: "./involve-row.component.html",
  styleUrls: ["./involve-row.component.scss"]
})
export class InvolveRowComponent {
  constructor(
    public basicService: BasicService,
  ) {
  }
}
