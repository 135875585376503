import {Component} from "@angular/core";
import {NavHelperService} from "src/app/services/nav-helper.service";
import {SettingsService} from "../../services/settings.service";
import {BasicService} from "../../services/basic.service";
import {BooleanHelper} from "../../utilities/boolean.util";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent {
  constructor(
    private navHelper: NavHelperService,
    private settings: SettingsService,
  ) {
    this.settings.showNav = true;
  }

  public goToIssues() {
    this.navHelper.goToIssues();
  }

}
