import {Component, Input} from "@angular/core";
import {BasicService} from "../../services/basic.service";

@Component({
  selector: "app-issue-base",
  templateUrl: "./issue-base.component.html",
  styleUrls: ["./issue-base.component.scss"]
})
export class IssueBaseComponent {
  @Input() public title;

  constructor(
    public basicService: BasicService,
  ) {
  }
}
