import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {IssueService} from "../../services/issue.service";

@Component({
  selector: "app-issue-details",
  templateUrl: "./issue-details.component.html",
  styleUrls: ["./issue-details.component.scss"]
})
export class IssueDetailsComponent implements OnInit {
  public issue = null;

  constructor(
    private route: ActivatedRoute,
    private issueService: IssueService,
  ) {
  }

  public ngOnInit() {
    const issueName = this.route.snapshot.paramMap.get("issueName");
    this.issue = this.issueService.issues.find((issue) => {
      return issue.name === issueName;
    });
  }

}
